import React, { lazy } from 'react';
// import {RouteProps} from 'react-router-dom';
import { mainPages, parkingModals, passwordModals, profileModals, reservationModals, subscriptionModals, subscriptionPaymentModals, testPages } from '../menu';
import { RouteType } from './routeTypes';

const MAINPAGES = {
    HOME: lazy(() => import('../pages/Home/index')),
    NEEDHELP: lazy(() => import('../pages/Faq/index')),
    SERVICE: lazy(()=>import('../pages/Service/index')),
    RECRUTMENT: lazy(()=>import('../pages/Careers/index')),
    ABOUT: lazy(()=>import('../pages/About/index')),
    EXPERTISE: lazy(()=>import('../pages/RoadCompany/index')),
    LEGALMENTIONS: lazy(()=>import('../pages/LegalInformation/index')),
    ROI: lazy(()=>import('../pages/Roi/index')),
    CITYPAGE: lazy(()=>import('../pages/CityPage/index')),
}

const PASSWORDMODALS = {
    RESETPASSWORD: lazy(()=>import('../pages/ForgotPsw/index')),
    PASSWORDUPDATE: lazy(()=>import('../pages/PasswordUpdate/index')),
}

const RESERVATIONMODALS = {
    KEYACCOUNTARCHIVEDRESERVATIONS: lazy(()=> import("../views/KeyAccountProfile/ArchivedReservations")),
    KEYACCOUNTCREATERESERVATIONS: lazy(()=>import('../views/KeyAccountProfile/AddBulkReservation')),
    KEYACCOUNTUPDATERESERVATIONS: lazy(()=>import('../views/KeyAccountProfile/UpdateBulkReservation')),
    KEYACCOUNTDETAILSRESERVATION: lazy(()=>import('../views/KeyAccountProfile/ReservationDetails')),
    UCLARCHIVEDRESERVATION: lazy(()=> import("../views/KeyAccountProfile/UclAccount/UCLArchivedReservations"))
}

const PARKINGMODALS = {
    PARKINGSERVICE: lazy(()=>import('../pages/Parkings')),
    PARKINGRESERVATION: lazy(()=>import('../pages/ReservationStaticPage')),
    PARKINGSUBSCRIPTION: lazy(()=>import('../pages/SubAndReservInfo')),
    PARKINGLIST: lazy(()=>import('../components/CityParks')),
    PARKINGRESERVATIONSUB: lazy(()=>import('../pages/PageStaticOne')),
    RESERVATIONCONFIRMATION: lazy(()=>import('../pages/Reservation/ConfirmReservation')),
}

const SUBSCRIPTIONMODALS = {
    SUBSCRIPTION: lazy(()=>import('../pages/SubscriptionInfoPage/index')),
    SUBSCRIPTIONCONFIRMATION: lazy(()=>import('../pages/Subscription/index')),  
    KEYACCOUNTARCHIVEDSUBSCRIPTIONS: lazy(()=> import("../views/KeyAccountProfile/Subscription/ArchivedSubscriptions")),
    KEYACCOUNTCREATESUBSCRIPTIONS: lazy(()=>import('../views/KeyAccountProfile/Subscription/AddBulkSubscription')),
    KEYACCOUNTUPDATESUBSCRIPTION : lazy(()=>import('../views/KeyAccountProfile/Subscription/UpdateSingleSubscription')),
    // KEYACCOUNTUPDATESUBSCRIPTIONS : lazy(()=>import('../views/KeyAccountProfile/Subscription/UpdateBulkSubscription')),
    // KEYACCOUNTDETAILSSUBSCRIPTION: lazy(()=>import('../views/KeyAccountProfile/Subscription/SubscriptionDetails')),
}

const SUBSCRIPTIONPAYMENTMODALS = {
    SUBSCRIPTIONPAYMENT: lazy(()=>import('../views/SubAndReservConfirmation/Subscription/payementSub')),
    PAYMENTREDIRECTION: lazy(()=>import('../pages/paymentRedirect')),
}

const PROFILEMODALS = {
    PROFILE: lazy(()=>import('../pages/Profile/index')),
    EDITPROFILE: lazy(()=>import('../views/Profile/EditeProfile/EditProfile')),
    VERIFYPROFILE: lazy(()=>import('../pages/Verification/index')),
}

const TEST = {
    LOADING: lazy(() => import('../pages/Loading')),
}

const presentation : RouteType[] = [
    {
        title:  mainPages.home.title,
        path: mainPages.home.path,
        element: <MAINPAGES.HOME />,
        auth: mainPages.home.auth,
    },
    {
        title: mainPages.needHelp.title,
        path: mainPages.needHelp.path,
        element: <MAINPAGES.NEEDHELP />,
        auth: mainPages.needHelp.auth,
    },
    {
        title: mainPages.service.title,
        path: mainPages.service.path,
        element: <MAINPAGES.SERVICE />,
        auth: mainPages.service.auth,
    },
    {
        title: mainPages.recrutment.title,
        path: mainPages.recrutment.path,
        element: <MAINPAGES.RECRUTMENT />,
        auth: mainPages.recrutment.auth,
    },
    {
        title: mainPages.about.title,
        path: mainPages.about.path,
        element: <MAINPAGES.ABOUT />,
        auth: mainPages.about.auth,
    },
    {
        title: mainPages.expertise.title,
        path: mainPages.expertise.path,
        element: <MAINPAGES.EXPERTISE />,
        auth: mainPages.expertise.auth,
    },
    {
        title: mainPages.legalMentions.title,
        path: mainPages.legalMentions.path,
        element: <MAINPAGES.LEGALMENTIONS />,
        auth: mainPages.legalMentions.auth,
    },
    {
        title: mainPages.roi.title,
        path: mainPages.roi.path,
        element: <MAINPAGES.ROI />,
        auth: mainPages.roi.auth,
    },
    {
        title: mainPages.cityPage.title,
        path: mainPages.cityPage.path,
        element: <MAINPAGES.CITYPAGE />,
        auth: mainPages.cityPage.auth,
    },

    {
        title: passwordModals.resetPassword.title,
        path: passwordModals.resetPassword.path,
        element: <PASSWORDMODALS.RESETPASSWORD />,
        auth: passwordModals.resetPassword.auth,
    },
    {
        title: passwordModals.passwordUpdate.title,
        path: passwordModals.passwordUpdate.path,
        element: <PASSWORDMODALS.PASSWORDUPDATE />,
        auth: passwordModals.passwordUpdate.auth,
    },

    {
        title: reservationModals.keyAccountCreateReservations.title,
        path: reservationModals.keyAccountCreateReservations.path,
        element: <RESERVATIONMODALS.KEYACCOUNTCREATERESERVATIONS />,
        auth: reservationModals.keyAccountCreateReservations.auth,
    },
    {
        title: reservationModals.keyAccountUpdateReservations.title,
        path: reservationModals.keyAccountUpdateReservations.path,
        element: <RESERVATIONMODALS.KEYACCOUNTUPDATERESERVATIONS />,
        auth: reservationModals.keyAccountUpdateReservations.auth,
    },
    {
        title: reservationModals.keyAccountDetailsReservation.title,
        path: reservationModals.keyAccountDetailsReservation.path,
        element: <RESERVATIONMODALS.KEYACCOUNTDETAILSRESERVATION />,
        auth: reservationModals.keyAccountDetailsReservation.auth,
    },
    {
        title: reservationModals.keyAccountArchivedReservations.title,
        path: reservationModals.keyAccountArchivedReservations.path,
        element: <RESERVATIONMODALS.KEYACCOUNTARCHIVEDRESERVATIONS />,
        auth: reservationModals.keyAccountArchivedReservations.auth,
    },
    {
        title: reservationModals.uclArchivedReservations.title,
        path: reservationModals.uclArchivedReservations.path,
        element: <RESERVATIONMODALS.UCLARCHIVEDRESERVATION />,
        auth: reservationModals.uclArchivedReservations.auth,
    },
    {
        title: parkingModals.parkingService.title,
        path: parkingModals.parkingService.path,
        element: <PARKINGMODALS.PARKINGSERVICE />,
        auth: parkingModals.parkingService.auth,
    },
    {
        title: parkingModals.parkingReservation.title,
        path: parkingModals.parkingReservation.path,
        element: <PARKINGMODALS.PARKINGRESERVATION />,
        auth: parkingModals.parkingReservation.auth,
    },
    {
        title: parkingModals.parkingSubscription.title,
        path: parkingModals.parkingSubscription.path,
        element: <PARKINGMODALS.PARKINGSUBSCRIPTION />,
        auth: parkingModals.parkingSubscription.auth,
        noAccess: parkingModals.parkingSubscription.noAccess,
    },
    {
        title: parkingModals.parkingList.title,
        path: parkingModals.parkingList.path,
        element: <PARKINGMODALS.PARKINGLIST />,
        auth: parkingModals.parkingList.auth,
    },
    {
        title: parkingModals.parkingReservationSub.title,
        path: parkingModals.parkingReservationSub.path,
        element: <PARKINGMODALS.PARKINGRESERVATIONSUB />,
        auth: parkingModals.parkingReservationSub.auth,
    },
    {
        title: parkingModals.reservationConfirmation.title,
        path: parkingModals.reservationConfirmation.path,
        element: <PARKINGMODALS.RESERVATIONCONFIRMATION />,
        auth: parkingModals.reservationConfirmation.auth,
        noAccess: parkingModals.reservationConfirmation.noAccess,
    },

    {
        title: subscriptionModals.subscription.title,
        path: subscriptionModals.subscription.path,
        element: <SUBSCRIPTIONMODALS.SUBSCRIPTION />,
        auth: subscriptionModals.subscription.auth,
    },
    {
        title: subscriptionModals.subscriptionConfirmation.title,
        path: subscriptionModals.subscriptionConfirmation.path,
        element: <SUBSCRIPTIONMODALS.SUBSCRIPTIONCONFIRMATION />,
        auth: subscriptionModals.subscriptionConfirmation.auth,
        noAccess: subscriptionModals.subscriptionConfirmation.noAccess,
    },

    {
        title: subscriptionModals.keyAccouncreateSubscription.title,
        path: subscriptionModals.keyAccouncreateSubscription.path,
        element: <SUBSCRIPTIONMODALS.KEYACCOUNTCREATESUBSCRIPTIONS />,
        auth: subscriptionModals.keyAccouncreateSubscription.auth,
    },
    {
        title: subscriptionModals.keyAccounUpdateSubscription.title,
        path: subscriptionModals.keyAccounUpdateSubscription.path,
        element: <SUBSCRIPTIONMODALS.KEYACCOUNTUPDATESUBSCRIPTION />,
        auth: subscriptionModals.keyAccounUpdateSubscription.auth,

    },
    // {
    //     title: subscriptionModals.keyAccounUpdateSubscriptions.title,
    //     path: subscriptionModals.keyAccounUpdateSubscriptions.path,
    //     element: <SUBSCRIPTIONMODALS.KEYACCOUNTUPDATESUBSCRIPTIONS />,
    //     auth: subscriptionModals.keyAccounUpdateSubscriptions.auth,

    // },
    // {
    //     title: subscriptionModals.keyAccounDetailsSubscription.title,
    //     path: subscriptionModals.keyAccounDetailsSubscription.path,
    //     element: <SUBSCRIPTIONMODALS.KEYACCOUNTDETAILSSUBSCRIPTION />,
    //     auth: subscriptionModals.keyAccounDetailsSubscription.auth,
    // },
    {
        title: subscriptionModals.keyAccountArchivedSubscriptions.title,
        path: subscriptionModals.keyAccountArchivedSubscriptions.path,
        element: <SUBSCRIPTIONMODALS.KEYACCOUNTARCHIVEDSUBSCRIPTIONS />,
        auth: subscriptionModals.keyAccountArchivedSubscriptions.auth,
    },
    {
        title: subscriptionPaymentModals.subscriptionPayment.title,
        path: subscriptionPaymentModals.subscriptionPayment.path,
        element: <SUBSCRIPTIONPAYMENTMODALS.SUBSCRIPTIONPAYMENT />,
        auth: subscriptionPaymentModals.subscriptionPayment.auth,
        noAccess: subscriptionPaymentModals.subscriptionPayment.noAccess,
    },
    {
        title: subscriptionPaymentModals.paymentRedirection.title,
        path: subscriptionPaymentModals.paymentRedirection.path,
        element: <SUBSCRIPTIONPAYMENTMODALS.PAYMENTREDIRECTION />,
        auth: subscriptionPaymentModals.paymentRedirection.auth,
    },

    {
        title: profileModals.profile.title,
        path: profileModals.profile.path,
        element: <PROFILEMODALS.PROFILE />,
        auth: profileModals.profile.auth,
    },
    {
        title: profileModals.editProfile.title,
        path: profileModals.editProfile.path,
        element: <PROFILEMODALS.EDITPROFILE />,
        auth: profileModals.editProfile.auth,
    },
    {
        title: profileModals.verifyProfile.title,
        path: profileModals.verifyProfile.path,
        element: <PROFILEMODALS.VERIFYPROFILE />,
        auth: profileModals.verifyProfile.auth,
    },

    {
        title: testPages.loading.title,
        path: testPages.loading.path,
        element: <TEST.LOADING />,
        auth: testPages.loading.auth,
    },
]

export const contents = [...presentation];