import React, { useState, FC } from "react";

// ** MUI imports
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";

// ** Components imports
import Register from "../../views/Authentication/register";
import ForgotPsw from "../../views/Authentication/forgotPsw";
import Login from "../../views/Authentication/login";

// ** Third party imports
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import { AccountOutline } from "mdi-material-ui";
import TwoFactorAuth from "../../views/Authentication/twoFactorAuth";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { GetToken } from "../../utils/getToken";

const LoginDialog: FC<ILoginDialog> = ({ handleCloseDialog, openDialog }) => {
  // ** States
  const [forgotPsw, setForgotPsw] = useState<Boolean>(false);
  const [register, setRegister] = useState<Boolean>(false);
  const [twoFactorAuth, setTwoFactorAuth] = useState<Boolean>(false);

  // ** Functions
  const openRegister = () => {
    setForgotPsw(false);
    setRegister(true);
    setTwoFactorAuth(false);
  };
  const openForgotPsw = () => {
    setForgotPsw(true);
    setRegister(false);
    setTwoFactorAuth(false);
  };
  const openLogin = () => {
    setForgotPsw(false);
    setRegister(false);
    setTwoFactorAuth(false);
  };
  const openTwoFactorAuth = () => {
    setForgotPsw(false);
    setRegister(false);
    setTwoFactorAuth(true);
  };

  return (
    <div>
      <Dialog
        onClose={() => {
          handleCloseDialog();
          setForgotPsw(false);
          setRegister(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        fullWidth
        maxWidth={"sm"}
        PaperProps={{
          style: { borderRadius: "30px" },
        }}
        sx={{
          maxWidth: "75rem",
          width: "100%",
          m: "auto",
        }}
      >
        {forgotPsw ? (
          <ForgotPsw handleClose={handleCloseDialog} />
        ) : // REGISTER
        register ? (
          <Register openLogin={openLogin} />
        ) : twoFactorAuth ? (
          <TwoFactorAuth
            openLogin={openLogin}
            handleClose={handleCloseDialog}
          />
        ) : (
          <Login
            openForgotPsw={openForgotPsw}
            openRegister={openRegister}
            handleClose={handleCloseDialog}
            openTwoFactorAuth={openTwoFactorAuth}
          />
        )}
      </Dialog>
    </div>
  );
};

export default LoginDialog;

export const LoginDialogBtn: FC<any> = ({ icon, visible, buttonLink }) => {
  // ** Translation
  const { t } = useTranslation();
  // ** States
  const [forgotPsw, setForgotPsw] = useState<Boolean>(false);
  const [register, setRegister] = useState<Boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [twoFactorAuth, setTwoFactorAuth] = useState<Boolean>(false);

  // ** Routes
  const navigate = useNavigate();

  // ** Hooks
  const userReducer = useSelector((state: any) => state?.auth);

  // ** Functions

  const isAuth = GetToken();
  // open modal
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  // close modal
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setTimeout(() => {
      openLogin();
    }, 500);
  };
  const openRegister = () => {
    setForgotPsw(false);
    setRegister(true);
    setTwoFactorAuth(false);
  };
  const openForgotPsw = () => {
    setForgotPsw(true);
    setRegister(false);
    setTwoFactorAuth(false);
  };
  const openLogin = () => {
    setForgotPsw(false);
    setRegister(false);
    setTwoFactorAuth(false);
  };
  const openTwoFactorAuth = () => {
    setForgotPsw(false);
    setRegister(false);
    setTwoFactorAuth(true);
  };

  return (
    <div>
      {icon ? (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleOpenDialog}
        >
          <AccountOutline style={{ fontSize: 28 }} />
        </IconButton>
      ) : visible ? (
        <Button
          sx={{
            borderRadius: "0.4rem",
            px: 2,
          }}
          disableRipple
          onClick={handleOpenDialog}
          variant="outlined"
        >
          {t("se connecter")}
        </Button>
      ) : buttonLink && !icon && !visible ? (
        //eslint-disable-next-line
        <a
          style={{
            textDecoration: "none",
            color: "#8a8a8a",
            cursor: "pointer",
          }}
          onClick={() => {
            isAuth
              ? navigate(`/profile`, {
                  state: {
                    value:
                      userReducer?.user?.userType === "KeyAccount" &&
                      userReducer?.user?.isUcl
                        ? 2
                        : 3,
                  }, // redirect to the contact info
                })
              : handleOpenDialog();
          }}
        >
          {t("Nous contacter")}
        </a>
      ) : (
        <Button
          sx={{
            color: "#fff",
            border: "1px solid white",
            borderRadius: "0.4rem",
            px: 2,
            "&:hover": {
              color: "#8A8A8A",
              backgroundColor: "transparent",
              borderColor: "#8A8A8A",
            },
          }}
          disableRipple
          onClick={handleOpenDialog}
        >
          {t("se connecter")}
        </Button>
      )}

      <Dialog
        onClose={() => {
          handleCloseDialog();
          setForgotPsw(false);
          setRegister(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        fullWidth
        maxWidth={"sm"}
        PaperProps={{
          style: { borderRadius: "30px" },
        }}
        sx={{
          maxWidth: "75rem",
          width: "100%",
          m: "auto",
        }}
      >
        {forgotPsw ? (
          <ForgotPsw handleClose={handleCloseDialog} />
        ) : // REGISTER
        register ? (
          <Register openLogin={openLogin} />
        ) : twoFactorAuth ? (
          <TwoFactorAuth
            openLogin={openLogin}
            handleClose={handleCloseDialog}
          />
        ) : (
          <Login
            openForgotPsw={openForgotPsw}
            openRegister={openRegister}
            handleClose={handleCloseDialog}
            openTwoFactorAuth={openTwoFactorAuth}
          />
        )}
      </Dialog>
    </div>
  );
};

interface ILoginDialog {
  handleCloseDialog?: any;
  openDialog: any;
}
