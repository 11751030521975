// ** React imports
import React, {
  useLayoutEffect,
  useEffect,
  useState,
  useContext,
  Suspense,
} from "react";

// ** Router imports
import { Route, Routes, useLocation } from "react-router-dom";

// ** Third party imports
import { ToastContainer } from "react-toastify";
import { BroadcastChannel } from "broadcast-channel";
import "react-toastify/dist/ReactToastify.css";
import { io } from "socket.io-client";
import { useTranslation } from "react-i18next";

// ** Components imports
import NavbarEffia from "./components/navbar/navbarEffia";
import Footer from "./components/footer";

// ** Function imports
import { generateRoute } from "./routes";
import { GetToken, deleteTokenUser, logoutAllTabs } from "./utils/getToken";
import AuthContext from "./context/authContext";

// ** Redux imports
import { useSelector } from "react-redux";
import { getNotifications, verifyAccount } from "./redux/auth/authSlice";
import { useAppDispatch } from "./redux/hooks";

// ** MUI imports
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import LinearIndeterminate from "./pages/Loading";
import Page404 from "./pages/Page404";

const logoutChannel = new BroadcastChannel("logout");

function App() {
  const location = useLocation();
  const userReducer = useSelector((state: any) => state?.auth?.user);
  const dispatch = useAppDispatch();

  const searchParams = new URLSearchParams(location.search);
  const tokenVerificationAccount = searchParams.get("accountverification");

  useEffect(() => {
    const fetch = async () => {
      await dispatch(verifyAccount(tokenVerificationAccount));
    };
    if (tokenVerificationAccount) {
      fetch();
    }
  }, [tokenVerificationAccount]);

  // ** Translation
  const { t } = useTranslation();

  // ** states
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);

  // ** Scroll to the top of the page
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  React.useEffect(() => {
    if (userReducer && Object.keys(userReducer).length > 0) {
      // Connection to socket io
      const socket = io(`${process.env.REACT_APP_BACKEND_URL}sultan`);
      // Check if the socket is connected
      socket.on("connect", () => {
        socket.emit("setup", userReducer.id);

        // Get notifications
        socket.on("send-notification", (result: any) => {
          dispatch(getNotifications());
          setShowSnackbar(true);
        });
      });

      // disconnect
      socket.on("disconnect", () => {});
    }
    //eslint-disable-next-line
  }, [userReducer]);

  logoutChannel.onmessage = (msg) => console.log(msg);

  useEffect(() => {
    logoutAllTabs();
  }, []);

  // ** Pass userType to routes for role management
  const { userType } = useContext(AuthContext);
  const renderedRoutes = generateRoute(userType);

  // useEffect(() => {
  //   if (Object.keys(userReducer).length === 0 && isToken) {
  //     deleteTokenUser();
  //   }
  // }, [userReducer, isToken]);

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <NavbarEffia />
      <div style={{ paddingTop: "5rem", flex: 1 }}>
        <Suspense fallback={<LinearIndeterminate />}>
          <Routes>
            <Route path="/">{renderedRoutes}</Route>
            <Route path="*" element={<Page404 />} />
          </Routes>
        </Suspense>
      </div>
      <div style={{ width: "100%", paddingTop: "2rem" }}>
        <Footer />
      </div>
      <ToastContainer />
      {/* snackbar */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={4000}
        onClose={() => setShowSnackbar(false)}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity="info"
          sx={{ backgroundColor: "rgb(2, 136, 209)", color: "white" }}
        >
          {t("Vous avez reçu une nouvelle notification")}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default App;
