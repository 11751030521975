import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

// create new payment
export const createSubscriptionPayment = createAsyncThunk(
  "payment/createSubscriptionPayment",
  async (createInfo: Object, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        `create-subscription-payment`,
        createInfo,
        {
          withCredentials: true,
        }
      );
      /*  if (result.data.Success) {
      window.open(result.data?.worldlineResponse?.merchantAction?.redirectData?.redirectURL);
      } */
      if (result.status !== 200) {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// create new payment
export const createReservationPayment = createAsyncThunk(
  "payment/createReservationPayment",
  async (paymentDetails: Object, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        `create-reservation-payment`,
        paymentDetails,
        {
          withCredentials: true,
        }
      );
      if (result.status !== 200) {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// get payment by id
export const threeDSPayment = createAsyncThunk(
  "payment/threeDSPayment",
  async ({ parameter1, currentLanguage }: { parameter1: any; currentLanguage: any }, { rejectWithValue }) => {
    try {
      const result = await axios.post(`get-threeds-result/${parameter1}`,{currentLanguage} ,{
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get payment by id
export const subscriptionThreeDS = createAsyncThunk(
  "payment/subscriptionThreeDS",
  async ( { parameter2, currentLanguage }: { parameter2: Object; currentLanguage: any } , { rejectWithValue }) => {
    try {
      const result = await axios.post(
        `get-threeds-subscription-result/${parameter2}`,{currentLanguage},
        {
          withCredentials: true,
        }
      );

      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    payment: {},
    payments: [],
    product: {},
    loading: false,
    errors: null,
  },
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: (builder) => {
    builder

      // create new payment
      .addCase(createSubscriptionPayment.pending, (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addCase(createSubscriptionPayment.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.product = action.payload.Data;
      })
      .addCase(
        createSubscriptionPayment.rejected,
        (state: any, action: any) => {
          state.loading = false;
          state.errors = action.payload.Description;
          state.payment = {};
        }
      )
      // create new reservation payment
      .addCase(createReservationPayment.pending, (state) => {
        state.payment = {};
        state.loading = true;
        state.errors = null;
      })
      .addCase(createReservationPayment.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.payment = action.payload.Payment;
        state.product = action.payload.Reservation;
      })
      .addCase(createReservationPayment.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.payment = {};
      })

      // Reservation 3DSecure
      .addCase(threeDSPayment.pending, (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addCase(threeDSPayment.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.payment = action.payload.Data;
      })
      .addCase(threeDSPayment.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
      })
      // Subscription 3DSecure
      .addCase(subscriptionThreeDS.pending, (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addCase(subscriptionThreeDS.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.payment = action.payload.Data;
      })
      .addCase(subscriptionThreeDS.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
      });
  },
});

export default paymentSlice.reducer;
