// ** React imports
import { FC, forwardRef, useEffect, useState } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";

// ** Icons imports
import ClockOutline from "mdi-material-ui/ClockOutline";
import CalendarMonthOutline from "mdi-material-ui/CalendarMonthOutline";

// ** Third Party Imports
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "moment/locale/fr";
import "moment/locale/nl";
import fr from "date-fns/locale/fr";
import nl from "date-fns/locale/nl";
import en from "date-fns/locale/en-US";
import { useTranslation } from "react-i18next";
import format from "date-fns/format";
import moment from "moment";

// ** Components imports
// ** Styles imports
import DatePickerWrapper from "../../styles/Datepicker";
import TimePickerWrapper from "../../styles/TimePickerWrapper";
import { useLocation } from "react-router-dom";

export const CustomDateInput: FC<any> = forwardRef((props, ref) => {
  // ** translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const startDate = props.start ? moment(props.start) : moment();
  startDate.locale(currentLanguage);

  const formattedDate = startDate.format("D MMM YYYY");
  const value = `${formattedDate}`;

  // const startDate = props.start
  //   ? moment(props.start).locale(currentLanguage).format("D MMM YYYY")
  //   : "";

  // const value = `${startDate}`;
  return (
    <>
      <InputBase
        sx={{
          flex: 1,
          fontSize: "",
          cursor: "pointer",
          width: "100%",
        }}
        placeholder={t("searchbar")}
        size="small"
        inputRef={ref}
        {...props}
        value={value}
      />
    </>
  );
});

export const CustomTimeInput: FC<any> = forwardRef((props, ref) => {
  // ** translation
  const { t } = useTranslation();
  const startDate = moment(props.start);

  // ** Start time only at x0 or x0

  const formattedDate = startDate.format("HH:mm");

  const value = `${formattedDate}`;
  return (
    <>
      <InputBase
        sx={{
          flex: 1,
          fontSize: "",
          cursor: "pointer",
        }}
        placeholder={t("searchbar")}
        size="small"
        inputRef={ref}
        {...props}
        value={value}
      />
    </>
  );
});

/***key acount input time begin*/
export const CustomInputTime: FC<any> = forwardRef((props, ref) => {
  // ** translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const startDate = moment(props.start);

  // ** Start time only at x0 or x0
  const currentMinute = startDate.minute();

  const roundedMinute = Math.round(currentMinute / 5) * 5;
  const newMinute = roundedMinute;

  startDate.minute(newMinute);

  const timeFormat = currentLanguage === "en" ? "hh:mm a" : "HH:mm";

  const formattedDate = startDate.format(timeFormat);

  const value = `${formattedDate}`;
  return (
    <>
      <InputBase
        sx={{
          // ml: 1,
          flex: 1,
          fontSize: "",
          cursor: "pointer",
        }}
        placeholder={t("searchbar")}
        size="small"
        inputRef={ref}
        {...props}
        value={value}
      />
    </>
  );
});
/*****end */
export const CustomInputRange: FC<any> = forwardRef((props, ref) => {
  const startDate = props.start ? format(props.start, "dd/MM/yyyy") : "";
  const endDate =
    props.end !== null ? ` - ${format(props.end, "dd/MM/yyyy")}` : null;
  const value = `${startDate}${endDate !== null ? endDate : ""}`;

  return (
    <InputBase
      sx={{
        ml: 1,
        fontSize: "",
        cursor: "pointer",
        width: "100%",
      }}
      placeholder=""
      size="small"
      inputRef={ref}
      {...props}
      value={value}
    />
  );
});

export const CustomDatePicker: FC<any> = ({
  title,
  date,
  minTime,
  getDate,
  minDate,
  prevDate, // startDate
  bottomBorderOnly,
  borderRadius,
}) => {
  // ** Hooks
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  registerLocale(
    currentLanguage,
    currentLanguage === "fr" ? fr : currentLanguage === "nl" ? nl : en
  );
  const [startDate, setDate] = useState<any>(date);
  const [selectedTime, setSelectedTime] = useState<any>(date);
  const [isEntry, setIsEntry] = useState<any>(true);

  // **

  const handleTimeChange = (time: any) => {
    // Adjust the selected time to the nearest 15-minute interval
    const roundedMinutes = Math.ceil(moment(time).minutes() / 15) * 15;
    const adjustedTime = moment(time).minutes(roundedMinutes);
    setSelectedTime(adjustedTime.toDate());
  };

  const combinedDate = new Date(startDate);
  combinedDate.setHours(selectedTime.getHours());
  combinedDate.setMinutes(selectedTime.getMinutes());

  useEffect(() => {
    setIsEntry(title?.toLowerCase() === "sortie" ? false : true);
  }, [title]);

  useEffect(() => {
    getDate(combinedDate);
    // eslint-disable-next-line
  }, [startDate, selectedTime]);

  // ** maxTime
  const endOfDay = moment(new Date()).endOf("day");
  const endOfDayAsDate = endOfDay.toDate();

  useEffect(() => {
    if (!isEntry) {
      const timeBetween = moment(date).diff(moment(prevDate), "minutes");
      const isSameDay = moment(date).isSame(moment(prevDate), "day");

      if (isSameDay && timeBetween < 60) {
        setSelectedTime(moment(prevDate).add(1, "hour").toDate());
      }
    }
    //eslint-disable-next-line
  }, [prevDate]);

  return (
    <Box
      sx={{
        flex: 1,
        borderBottom: bottomBorderOnly
          ? "1px solid #D9D9D9"
          : "1px solid #D9D9D9",
        borderLeft: bottomBorderOnly ? "none" : "1px solid #D9D9D9",
        borderRight: bottomBorderOnly ? "none" : "1px solid #D9D9D9",
        borderTop: bottomBorderOnly ? "none" : "1px solid #D9D9D9",
        borderRadius: borderRadius ? borderRadius : "0.4rem",

        display: "flex",
        color: "#8A8A8A",
        justifyContent: "space-between",
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          borderRight: "1px solid #D9D9D9",
          p: 1,
        }}
      >
        <CalendarMonthOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <Box>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t(title)}
          </Typography>
          <DatePickerWrapper>
            <DatePicker
              locale={currentLanguage}
              minDate={isEntry ? new Date() : minDate}
              selected={date}
              onChange={(date: any) => setDate(date)}
              customInput={
                <CustomDateInput
                  label={t("Sélectionner une date")}
                  start={date}
                />
              }
            />
          </DatePickerWrapper>
        </Box>
      </Box>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          p: 1,
        }}
      >
        <ClockOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <Box>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Heure")}
          </Typography>
          <TimePickerWrapper>
            <DatePicker
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              selected={selectedTime}
              onChange={handleTimeChange}
              minTime={minTime}
              maxTime={endOfDayAsDate}
              customInput={
                <CustomTimeInput
                  label={t("Sélectionner une date")}
                  start={selectedTime}
                />
              }
            />
          </TimePickerWrapper>
        </Box>
      </Box>
    </Box>
  );
};

export const DatePickerBox: FC<any> = ({
  title,
  getStartDate,
  start,
  // endDate
}) => {
  // ** translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  registerLocale(
    currentLanguage,
    currentLanguage === "fr" ? fr : currentLanguage === "nl" ? nl : en
  );

  const [startDate, setEndDate] = useState<any>(new Date());
  const [selectedTime, setSelectedTime] = useState<any>(new Date());
  const [minTimeSortie, setMinTimeSortie] = useState<any>();

  const handleTimeChange = (time: any) => {
    setSelectedTime(time);
  };

  useEffect(() => {
    getStartDate(startDate, selectedTime);
    // eslint-disable-next-line
  }, [startDate, selectedTime]);

  // ** Disable previous hours => start from current time until the end of the day (minTime/maxTime)
  const minTime = moment().toDate();
  const endOfDay = moment(new Date()).endOf("day");
  const endOfDayAsDate = endOfDay.toDate();

  const endOfDaySortie = moment(new Date()).endOf("day");
  const maxTimeSortie =
    endOfDaySortie.diff(startDate, "minutes") < 30
      ? startDate
      : endOfDaySortie.toDate();

  useEffect(() => {
    const now = moment();
    const startTime = moment(start, "YYYY-MM-DDTHH:mm:ss");
    const diffInMinutes = startTime.diff(now, "minutes");
    if (diffInMinutes < 30) {
    }

    const minTimeSortie = moment()
      .year(startTime.year())
      .month(startTime.month())
      .date(startTime.date())
      .hour(startTime.hour())
      .minute(startTime.minute())
      .add(30, "minutes")
      .toDate();

    setMinTimeSortie(minTimeSortie);
  }, [start]);
  return (
    <Box
      sx={{
        flex: 1,
        borderLeft: "1px solid #D9D9D9",
        display: "flex",
        color: "#8A8A8A",
        justifyContent: "space-between",
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 1,
          cursor: "pointer",
          flex: 1,
        }}
      >
        <CalendarMonthOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <Box component="div">
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {title}
          </Typography>
          <DatePickerWrapper>
            <DatePicker
              locale={currentLanguage}
              minDate={new Date()}
              selected={startDate}
              onChange={(date: any) => setEndDate(date)}
              customInput={
                <CustomDateInput
                  label={t("Sélectionner une date")}
                  start={startDate}
                />
              }
            />
          </DatePickerWrapper>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          flex: 1,
        }}
      >
        <ClockOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <Box component="div">
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Heure")}
          </Typography>
          <TimePickerWrapper>
            {/* {title === t("Date d'entrée") ? (
             */}
            {title === t("Date d'entrée") ? (
              <DatePicker
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                selected={selectedTime}
                onChange={handleTimeChange}
                minTime={minTime}
                maxTime={endOfDayAsDate} // sets maximum time to end of the day
                customInput={
                  <CustomTimeInput
                    label={t("Sélectionner une date")}
                    start={selectedTime}
                  />
                }
              />
            ) : (
              <DatePicker
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                selected={selectedTime}
                onChange={handleTimeChange}
                minTime={minTimeSortie}
                maxTime={maxTimeSortie} // sets maximum time to end of the day
                customInput={
                  <CustomTimeInput
                    label={t("Sélectionner une date")}
                    start={selectedTime}
                  />
                }
              />
            )}
          </TimePickerWrapper>
        </Box>
      </Box>
    </Box>
  );
};

export const EntryDatePickerResponsive: FC<any> = ({ getStartDate }) => {
  // ** Hooks
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const prevStartDate = location?.state?.startDate;
  const currentLanguage = i18n.language;

  registerLocale(
    currentLanguage,
    currentLanguage === "fr" ? fr : currentLanguage === "nl" ? nl : en
  );
  const [startDate, setStartDate] = useState<any>(new Date());
  // ** Disable previous hours => start from current time until the end of the day (minTime/maxTime)
  const minTime = moment().toDate();
  if (moment(startDate).isSame(moment(), "day")) {
    minTime.setHours(moment().hours());
    minTime.setMinutes(Math.ceil(moment().minutes() / 15) * 15);
  } else {
    minTime.setHours(0);
    minTime.setMinutes(0);
  }

  const [selectedTime, setSelectedTime] = useState<any>(minTime);

  useEffect(() => {
    if (prevStartDate) {
      setStartDate(prevStartDate);
      setSelectedTime(prevStartDate);
    }
  }, []);
  // **

  const handleTimeChange = (time: any) => {
    // Adjust the selected time to the nearest 15-minute interval
    const roundedMinutes = Math.ceil(moment(time).minutes() / 15) * 15;
    const adjustedTime = moment(time).minutes(roundedMinutes);

    // Set the selected time state
    setSelectedTime(adjustedTime.toDate());
  };
  const combinedDate = new Date(startDate);

  combinedDate.setHours(selectedTime.getHours());
  combinedDate.setMinutes(selectedTime.getMinutes());

  useEffect(() => {
    getStartDate(combinedDate);
    // eslint-disable-next-line
  }, [startDate, selectedTime]);

  const endOfDay = moment(new Date()).endOf("day");
  const endOfDayAsDate = endOfDay.toDate();
  return (
    <Box
      sx={{
        flex: 1,
        borderBottom: "2px solid #D9D9D9",
        display: "flex",
        color: "#8A8A8A",
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          p: 1,
          cursor: "pointer",
        }}
      >
        <CalendarMonthOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Entrée")}
          </Typography>
          <DatePickerWrapper>
            <DatePicker
              locale={currentLanguage}
              minDate={new Date()}
              selected={startDate}
              onChange={(date: any) => setStartDate(date)}
              customInput={
                <CustomDateInput
                  label={t("Sélectionner une date")}
                  start={startDate}
                />
              }
            />
          </DatePickerWrapper>
        </div>
      </Box>
      <Box sx={{ borderRight: "2px solid #D9D9D9", mb: 1 }}></Box>
      <Box
        sx={{
          p: 1,
          flex: 1,
          display: "flex",
          cursor: "pointer",
          alignItems: "center",
        }}
      >
        <ClockOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Heure")}
          </Typography>
          <TimePickerWrapper>
            <DatePicker
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              selected={selectedTime}
              onChange={handleTimeChange}
              minTime={minTime}
              maxTime={endOfDayAsDate} // sets maximum time to end of the day
              customInput={
                <CustomTimeInput
                  label={t("Sélectionner une date")}
                  start={selectedTime}
                />
              }
            />
          </TimePickerWrapper>
        </div>
      </Box>
    </Box>
  );
};

export const ExitDatePickerResponsive: FC<any> = ({
  getEndDate,
  startDate,
}) => {
  // ** Hooks
  const { t, i18n } = useTranslation();
  const location = useLocation();
  // const prevEndDate = location?.state?.endDate;
  const currentLanguage = i18n.language;

  registerLocale(
    currentLanguage,
    currentLanguage === "fr" ? fr : currentLanguage === "nl" ? nl : en
  );

  const [endDate, setEndDate] = useState<any>(new Date());
  const [minTime, setMinTime] = useState<any>(new Date());
  const [minDate, setMinDate] = useState<any>(new Date());
  const [prevEndDate, setPrevEndDate] = useState<any>(location?.state?.endDate);
  const [selectedTime, setSelectedTime] = useState<any>(minTime);

  useEffect(() => {
    if (prevEndDate) {
      setEndDate(prevEndDate);
      setSelectedTime(prevEndDate);
    }
  }, [prevEndDate]);

  // **

  const combinedDate = new Date(endDate);

  combinedDate.setHours(selectedTime.getHours());
  combinedDate.setMinutes(selectedTime.getMinutes());

  const handleTimeChange = (time: any) => {
    // Adjust the selected time to the nearest 15-minute interval
    // Set the selected time state
    setSelectedTime(time);
    setPrevEndDate(null);
  };

  const handleDateChange = (time: any) => {
    // Adjust the selected time to the nearest 15-minute interval
    // Set the selected time state
    setEndDate(time);
    setPrevEndDate(null);
  };
  useEffect(() => {
    getEndDate(combinedDate);
    // eslint-disable-next-line
  }, [endDate, selectedTime]);
  const endOfDay = moment(new Date()).endOf("day");
  const endOfDayAsDate = endOfDay.toDate();

  useEffect(() => {
    // ** Disable previous hours => end from current time until the end of the day (minTime/maxTime)
    const updatedMinTime = moment(startDate).add(1, "hour");
    const roundedMinutes = Math.ceil(updatedMinTime.minutes() / 15) * 15;
    updatedMinTime.minutes(roundedMinutes);
    // const isSameDay = moment(prevEndDate).isSame(moment(startDate), "day");
    if (!prevEndDate) {
      if (moment(endDate).isSame(moment(startDate), "day")) {
        setMinTime(updatedMinTime.toDate());
        if (moment(selectedTime).diff(moment(startDate), "minutes") < 60) {
          setSelectedTime(updatedMinTime.toDate());
        }
        // **
      }
      if (moment(endDate).isSame(moment(startDate), "day")) {
        setEndDate(startDate);
        const updatedMinTime = moment(startDate).add(1, "hour");
        const roundedMinutes = Math.ceil(updatedMinTime.minutes() / 15) * 15;
        updatedMinTime.minutes(roundedMinutes);
        setMinTime(updatedMinTime.toDate());
      } else if (moment(endDate).isAfter(moment(startDate), "day")) {
        setMinTime(new Date("00:00:00"));
      }
    } else {
      const roundedSelectedTime = moment(prevEndDate)
        .startOf("hour")
        .add(Math.ceil(moment(prevEndDate).minutes() / 15) * 15, "minutes")
        .toDate();
      if (moment(prevEndDate).diff(moment(startDate), "hours") > 1) {
        setEndDate(prevEndDate);
        setSelectedTime(roundedSelectedTime);
      } else {
        const updatedMinTime = moment(startDate).add(1, "hour");
        const roundedMinutes = Math.ceil(updatedMinTime.minutes() / 15) * 15;
        updatedMinTime.minutes(roundedMinutes);
      }

      setMinDate(startDate);
      // }
    }
  }, [startDate, endDate, prevEndDate]);
  // ** if endDate is bigger than the startDate
  useEffect(() => {
    const start = moment(startDate).format("YYYY-DD-MM HH:mm");
    const end = moment(endDate).format("YYYY-DD-MM HH:mm");

    if (!prevEndDate && start > end) {
      setEndDate(new Date(startDate));
      setMinDate(new Date(startDate));
    }
    const endOfDay = moment(moment(selectedTime).endOf("day")).diff(
      startDate,
      "minutes"
    );
    const isSameDay = moment(endDate).isSame(moment(startDate), "day");

    const updatedMinTime = moment(startDate).add(1, "hour");
    const roundedMinutes = Math.ceil(updatedMinTime.minutes() / 15) * 15;
    updatedMinTime.minutes(roundedMinutes);
    if (isSameDay && endOfDay < 60) {
      const addDay = moment(startDate).add(1, "days").toDate();
      setEndDate(addDay);
      setMinDate(addDay);
      setMinTime(moment(updatedMinTime).toDate());
      setSelectedTime(moment(updatedMinTime).toDate());
    } else {
      setMinDate(new Date(startDate));
    }
    // eslint-disable-next-line
  }, [startDate, prevEndDate]);

  return (
    <Box
      sx={{
        flex: 1,
        borderBottom: "2px solid #D9D9D9",
        display: "flex",
        color: "#8A8A8A",
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          p: 1,
          cursor: "pointer",
        }}
      >
        <CalendarMonthOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Entrée")}
          </Typography>
          <DatePickerWrapper>
            <DatePicker
              locale={currentLanguage}
              minDate={minDate}
              selected={endDate}
              onChange={handleDateChange}
              customInput={
                <CustomDateInput
                  label={t("Sélectionner une date")}
                  start={endDate}
                />
              }
            />
          </DatePickerWrapper>
        </div>
      </Box>
      <Box sx={{ borderRight: "2px solid #D9D9D9", mb: 1 }}></Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          p: 1,
          cursor: "pointer",
        }}
      >
        <ClockOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Heure")}
          </Typography>
          <TimePickerWrapper>
            <DatePicker
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              selected={selectedTime}
              onChange={handleTimeChange}
              minTime={minTime}
              maxTime={endOfDayAsDate} // sets maximum time to end of the day
              customInput={
                <CustomTimeInput
                  label={t("Sélectionner une date")}
                  start={selectedTime}
                />
              }
            />
          </TimePickerWrapper>
        </div>
      </Box>
    </Box>
  );
};
export const CustomDatePickerNoBorder: FC<any> = ({
  title,
  date,
  minTime,
  getDate,
  minDate,
  prevDate, // startDate
}) => {
  // ** translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  registerLocale(
    currentLanguage,
    currentLanguage === "fr" ? fr : currentLanguage === "nl" ? nl : en
  );
  const [startDate, setDate] = useState<any>(date);
  const [selectedTime, setSelectedTime] = useState<any>(date);
  const [isEntry, setIsEntry] = useState<any>(true);

  // **

  const handleTimeChange = (time: any) => {
    // Adjust the selected time to the nearest 15-minute interval
    const roundedMinutes = Math.ceil(moment(time).minutes() / 15) * 15;
    const adjustedTime = moment(time).minutes(roundedMinutes);
    setSelectedTime(adjustedTime.toDate());
  };

  const combinedDate = new Date(startDate);
  combinedDate.setHours(selectedTime.getHours());
  combinedDate.setMinutes(selectedTime.getMinutes());

  useEffect(() => {
    setIsEntry(title?.toLowerCase() === "sortie" ? false : true);
  }, [title]);

  useEffect(() => {
    getDate(combinedDate);
    // eslint-disable-next-line
  }, [startDate, selectedTime]);

  // ** maxTime
  const endOfDay = moment(new Date()).endOf("day");
  const endOfDayAsDate = endOfDay.toDate();

  useEffect(() => {
    if (!isEntry) {
      const timeBetween = moment(date).diff(moment(prevDate), "minutes");
      if (timeBetween < 60) {
        setSelectedTime(moment(prevDate).add(1, "hour").toDate());
      }
    }
    //eslint-disable-next-line
  }, [prevDate]);

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        color: "#8A8A8A",
        justifyContent: "space-between",
        maxWidth: "100%",
        borderLeft: { md: "none", xs: "1px solid #D9D9D9" },
        bgcolor: "white",
        borderRadius: { md: 0, sm: 0, xs: ".4rem" },
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          p: 1,
          cursor: "pointer",
        }}
      >
        <CalendarMonthOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t(title)}
          </Typography>
          <DatePickerWrapper>
            <DatePicker
              locale={currentLanguage}
              minDate={isEntry ? new Date() : minDate}
              selected={date}
              onChange={(date: any) => setDate(date)}
              customInput={
                <CustomDateInput
                  label={t("Sélectionner une date")}
                  start={date}
                />
              }
            />
          </DatePickerWrapper>
        </div>
      </Box>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          px: 2,
          py: 1,
          cursor: "pointer",
        }}
      >
        <ClockOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Heure")}
          </Typography>
          <TimePickerWrapper>
            <DatePicker
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              selected={selectedTime}
              onChange={handleTimeChange}
              minTime={minTime}
              maxTime={endOfDayAsDate}
              customInput={
                <CustomTimeInput
                  label={t("Sélectionner une date")}
                  start={selectedTime}
                />
              }
            />
          </TimePickerWrapper>
        </div>
      </Box>
    </Box>
  );
};
export const ExitDatePickerNoBorder: FC<any> = ({ getEndDate, startDate }) => {
  // ** translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  registerLocale(
    currentLanguage,
    currentLanguage === "fr" ? fr : currentLanguage === "nl" ? nl : en
  );
  const minDateSortie = moment(startDate).toDate();
  const [endDate, setEndDate] = useState<any>(new Date());
  const [minTime, setMinTime] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState<any>(new Date());
  const handleTimeChange = (time: any) => {
    setSelectedTime(time);
  };

  useEffect(() => {
    getEndDate(endDate, selectedTime);
    // eslint-disable-next-line
  }, [endDate, selectedTime]);

  // ** Disable previous hours => start from current time until the end of the day (minTime/maxTime)
  useEffect(() => {
    const startDateTime = new Date(startDate);
    const minTime = new Date(startDateTime.getTime());
    // it should be + 30 but the time zone keeps on adding 1h
    minTime.setUTCMinutes(minTime.getUTCMinutes() - 30);

    setMinTime(minTime);
  }, [startDate]);

  const endOfDay = moment(new Date()).endOf("day");
  const endOfDayAsDate = endOfDay.toDate();

  return (
    <Box
      sx={{
        flex: 1,
        borderLeft: "1px solid #D9D9D9",
        display: "flex",
        color: "#8A8A8A",
        justifyContent: "space-between",
        maxWidth: "100%",
        bgcolor: "white",
        borderRadius: { md: 0, sm: 0, xs: ".4rem" },
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          p: 1,
          cursor: "pointer",
        }}
      >
        <CalendarMonthOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Sortie")}
          </Typography>
          <DatePickerWrapper>
            <DatePicker
              locale={currentLanguage}
              minDate={minDateSortie}
              selected={endDate}
              onChange={(date: any) => setEndDate(date)}
              customInput={
                <CustomDateInput
                  label={t("Sélectionner une date")}
                  start={endDate}
                />
              }
            />
          </DatePickerWrapper>
        </div>
      </Box>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          p: 1,
          cursor: "pointer",
        }}
      >
        <ClockOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Heure")}
          </Typography>
          <TimePickerWrapper>
            <DatePicker
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              selected={selectedTime}
              onChange={handleTimeChange}
              minTime={minTime}
              maxTime={endOfDayAsDate} // sets maximum time to end of the day
              customInput={
                <CustomTimeInput
                  label={t("Sélectionner une date")}
                  start={selectedTime}
                />
              }
            />
          </TimePickerWrapper>
        </div>
      </Box>
    </Box>
  );
};

export const EntryDatePickerUpdate: FC<any> = ({
  getStartDate,
  reservStartDate,
}) => {
  // ** translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  registerLocale(
    currentLanguage,
    currentLanguage === "fr" ? fr : currentLanguage === "nl" ? nl : en
  );

  // testing here -1 hour caused by the timezone / check the output in Belguim
  const minusHour = moment(reservStartDate).subtract({ hours: 1 }).format();

  const [startDate, setStartDate] = useState<any>(new Date(reservStartDate));
  const [selectedTime, setSelectedTime] = useState<any>(new Date(minusHour));
  const handleTimeChange = (time: any) => {
    setSelectedTime(time);
  };

  useEffect(() => {
    getStartDate(startDate, selectedTime);
    // eslint-disable-next-line
  }, [startDate, selectedTime]);

  // ** Disable previous hours => start from current time until the end of the day (minTime/maxTime)
  const minTime = moment().toDate();
  if (moment(startDate).isSame(moment(), "day")) {
    minTime.setHours(moment().hours());
    minTime.setMinutes(moment().minutes());
  } else {
    minTime.setHours(0);
    minTime.setMinutes(0);
  }
  const endOfDay = moment(new Date()).endOf("day");
  const endOfDayAsDate = endOfDay.toDate();

  // const localDate = moment(new Date(reservStartDate)).format();
  // const { timeZone: localTZ } = Intl.DateTimeFormat().resolvedOptions();
  // const apiTimeFromPickerLocalDate = moment.utc(localDate).format();

  // const pickerTimeFromApiTime = moment.utc(apiTimeFromPickerLocalDate).toDate();

  return (
    <Box
      sx={{
        flex: 1,
        border: "1px solid #D9D9D9",
        borderRadius: "0.4rem",
        display: "flex",
        color: "#8A8A8A",
        justifyContent: "space-between",
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          borderRight: "1px solid #D9D9D9",
          p: 1,
          cursor: "pointer",
        }}
      >
        <CalendarMonthOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Entrée")}
          </Typography>
          <DatePickerWrapper>
            <DatePicker
              locale={currentLanguage}
              minDate={new Date()}
              selected={startDate}
              onChange={(date: any) => setStartDate(date)}
              customInput={
                <CustomDateInput
                  label={t("Sélectionner une date")}
                  start={startDate}
                />
              }
            />
          </DatePickerWrapper>
        </div>
      </Box>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          p: 1,
          cursor: "pointer",
        }}
      >
        <ClockOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Heure")}
          </Typography>
          <TimePickerWrapper>
            <DatePicker
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              selected={selectedTime}
              onChange={handleTimeChange}
              minTime={minTime}
              maxTime={endOfDayAsDate} // sets maximum time to end of the day
              customInput={
                <CustomTimeInput
                  label={t("Sélectionner une date")}
                  start={selectedTime}
                />
              }
            />
          </TimePickerWrapper>
        </div>
      </Box>
    </Box>
  );
};

export const ExitDatePickerUpdate: FC<any> = ({
  getEndDate,
  reservStartDate,
  reservEndDate,
}) => {
  // ** translation
  // ** translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  registerLocale(
    currentLanguage,
    currentLanguage === "fr" ? fr : currentLanguage === "nl" ? nl : en
  );
  const minusHour = moment(reservEndDate).subtract({ hours: 1 }).format();

  const minDateSortie = moment(reservStartDate).toDate();
  const [endDate, setEndDate] = useState<any>(new Date(reservEndDate));
  const [minTime, setMinTime] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState<any>(new Date(minusHour));
  const handleTimeChange = (time: any) => {
    setSelectedTime(time);
  };

  useEffect(() => {
    getEndDate(endDate, selectedTime);
    // eslint-disable-next-line
  }, [endDate, selectedTime]);

  // ** Disable previous hours => start from current time until the end of the day (minTime/maxTime)
  useEffect(() => {
    const startDateTime = new Date(reservStartDate);
    const minTime = new Date(startDateTime.getTime());
    // it should be + 30 but the time zone keeps on adding 1h
    minTime.setUTCMinutes(minTime.getUTCMinutes() - 30);

    setMinTime(minTime);
  }, [reservStartDate]);

  const endOfDay = moment(new Date()).endOf("day");
  const endOfDayAsDate = endOfDay.toDate();

  return (
    <Box
      sx={{
        flex: 1,
        border: "1px solid #D9D9D9",
        borderRadius: "0.4rem",
        display: "flex",
        color: "#8A8A8A",
        justifyContent: "space-between",
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          borderRight: "1px solid #D9D9D9",
          p: 1,
          cursor: "pointer",
        }}
      >
        <CalendarMonthOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Sortie")}
          </Typography>
          <DatePickerWrapper>
            <DatePicker
              locale={currentLanguage}
              minDate={minDateSortie}
              selected={endDate}
              onChange={(date: any) => setEndDate(date)}
              customInput={
                <CustomDateInput
                  label={t("Sélectionner une date")}
                  start={endDate}
                />
              }
            />
          </DatePickerWrapper>
        </div>
      </Box>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          p: 1,
          cursor: "pointer",
        }}
      >
        <ClockOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Heure")}
          </Typography>
          <TimePickerWrapper>
            <DatePicker
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              selected={selectedTime}
              onChange={handleTimeChange}
              minTime={minTime}
              maxTime={endOfDayAsDate} // sets maximum time to end of the day
              customInput={
                <CustomTimeInput
                  label={t("Sélectionner une date")}
                  start={selectedTime}
                />
              }
            />
          </TimePickerWrapper>
        </div>
      </Box>
    </Box>
  );
};
export const InputTime: FC<any> = ({ value, onChange }) => {
  const { t } = useTranslation();

  return (
    <TimePickerWrapper>
      <DatePicker
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={5}
        dateFormat="HH:mm"
        timeFormat="HH:mm"
        selected={value}
        onChange={onChange}
        customInput={
          <CustomTimeInput label={t("Sélectionner une date")} start={value} />
        }
      />
    </TimePickerWrapper>
  );
};
export const TimeInput: FC<any> = ({ value, onChange }) => {
  const { t } = useTranslation();
  return (
    <TimePickerWrapper>
      <DatePicker
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={5}
        timeFormat="HH:mm"
        selected={value}
        onChange={onChange}
        customInput={
          <CustomInputTime label={t("Sélectionner une date")} start={value} />
        }
      />
    </TimePickerWrapper>
  );
};
/**Key account date time picker */
const calculateMaxDateForSkidataSimpleRes = (
  inputDateStr: any,
  totalMinites: any
) => {
  const inputDate = new Date(inputDateStr);
  const resultDate = new Date(
    inputDate.getTime() + parseInt(totalMinites) * 60 * 1000
  );
  return resultDate.toISOString();
};

export const KeyAccountEntryDatePicker: FC<any> = ({ getStartDate }) => {
  // ** translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  registerLocale(
    currentLanguage,
    currentLanguage === "fr" ? fr : currentLanguage === "nl" ? nl : en
  );

  const [startDate, setStartDate] = useState<any>(new Date());
  const [selectedTime, setSelectedTime] = useState<any>(new Date());
  const handleTimeChange = (time: any) => {
    setSelectedTime(time);
  };
  useEffect(() => {
    getStartDate(startDate, selectedTime);
    // eslint-disable-next-line
  }, [startDate, selectedTime]);

  // ** Disable previous hours => start from current time until the end of the day (minTime/maxTime)
  const minTime = moment().toDate();
  if (moment(startDate).isSame(moment(), "day")) {
    minTime.setHours(moment().hours());
    minTime.setMinutes(moment().minutes());
  } else {
    minTime.setHours(0);
    minTime.setMinutes(0);
  }
  const endOfDay = moment(new Date()).endOf("day");
  const endOfDayAsDate = endOfDay.toDate();
  return (
    <Box
      sx={{
        flex: 1,
        border: "1px solid #D9D9D9",
        borderRadius: "0.4rem",
        display: "flex",
        color: "#8A8A8A",
        justifyContent: "space-between",
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          borderRight: "1px solid #D9D9D9",
          p: 1,
          cursor: "pointer",
        }}
      >
        <CalendarMonthOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Entrée")}
          </Typography>
          <DatePickerWrapper>
            <DatePicker
              showYearDropdown
              locale={currentLanguage}
              minDate={new Date()}
              selected={startDate}
              onChange={(date: any) => setStartDate(date)}
              customInput={
                <CustomDateInput
                  label={t("Sélectionner une date")}
                  start={startDate}
                />
              }
            />
          </DatePickerWrapper>
        </div>
      </Box>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          p: 1,
          cursor: "pointer",
        }}
      >
        <ClockOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Heure")}
          </Typography>
          <TimePickerWrapper>
            <DatePicker
              locale={currentLanguage}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={5}
              dateFormat={currentLanguage === "en" ? "HH:mm" : "hh:mm"}
              timeFormat="HH:mm"
              selected={selectedTime}
              onChange={handleTimeChange}
              minTime={minTime}
              maxTime={endOfDayAsDate} // sets maximum time to end of the day
              customInput={
                <CustomInputTime
                  label={t("Sélectionner une date")}
                  start={selectedTime}
                />
              }
            />
          </TimePickerWrapper>
        </div>
      </Box>
    </Box>
  );
};

export const KeyAccountExitDatePicker: FC<any> = ({
  getEndDate,
  startDate,
  finalEndDate,
  pmsType,
  ticketType,
}) => {
  // ** translation
  // ** translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  registerLocale(
    currentLanguage,
    currentLanguage === "fr" ? fr : currentLanguage === "nl" ? nl : en
  );

  const [minEndDate, setMinEndDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [maxEndDate, setMaxEndDate] = useState<any>(new Date());

  const [minTime, setMinTime] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState<any>(new Date());
  const [maxTime, setMaxTime] = useState<any>(new Date());

  const [showFullTime, setShowFullTime] = useState(false);
  const [showEndOfDay, setShowEndOfDay] = useState(false);

  const handleTimeChange = (time: any) => {
    setSelectedTime(time);
  };

  useEffect(() => {
    getEndDate(endDate, selectedTime);
    // eslint-disable-next-line
  }, [endDate, selectedTime]);

  // ** Disable previous hours => start from current time until the end of the day (minTime/maxTime)
  useEffect(() => {
    const startDateTime = new Date(startDate);
    const timeData = new Date(startDateTime.getTime());
    // it should be + 30 but the time zone keeps on adding 1h
    timeData.setUTCMinutes(timeData.getUTCMinutes() - 60);

    setMinEndDate(timeData);
    setMinTime(timeData);

    const starterDate = moment.utc(startDate).format("YYYY-MM-DD");
    const enderDate = moment.utc(finalEndDate).format("YYYY-MM-DD");

    if (pmsType === "Skidata" && ticketType === "Réservation simple") {
      const getMaxdate = calculateMaxDateForSkidataSimpleRes(startDate, 89280);
      const maxDate = moment.utc(getMaxdate).format("YYYY-MM-DD");

      const limitDateTime = new Date(getMaxdate);
      const maxDateTime = new Date(limitDateTime.getTime());
      // it should be + 30 but the time zone keeps on adding 1h
      maxDateTime.setUTCMinutes(maxDateTime.getUTCMinutes() - 60);

      setMaxEndDate(maxDateTime);

      if (enderDate > maxDate) {
        setShowFullTime(true);
        setMaxTime(maxDateTime);
        setEndDate(maxDateTime);
        setSelectedTime(maxDateTime);
        setShowEndOfDay(false);
      } else if (maxDate > enderDate) {
        setShowFullTime(true);
        setShowEndOfDay(true);
      } else if (enderDate === maxDate) {
        setShowFullTime(true);
        setShowEndOfDay(false);
        setMaxTime(maxDateTime);
      }
    }

    const isAfter = moment.utc(startDate).isAfter(moment.utc(finalEndDate));
    if (isAfter) {
      setEndDate(timeData);
      setSelectedTime(timeData);
    }

    if (starterDate === enderDate) {
      setShowFullTime(false);
      setMinTime(timeData);
      //setSelectedTime(timeData);
      setShowEndOfDay(true);
    }
    if (enderDate > starterDate) {
      setShowFullTime(true);
    } else {
      setShowFullTime(false);
    }
  }, [startDate, finalEndDate, ticketType]);

  const endOfDay = moment(new Date()).endOf("day");
  const endOfDayAsDate = endOfDay.toDate();

  return (
    <Box
      sx={{
        flex: 1,
        border: "1px solid #D9D9D9",
        borderRadius: "0.4rem",
        display: "flex",
        color: "#8A8A8A",
        justifyContent: "space-between",
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          borderRight: "1px solid #D9D9D9",
          p: 1,
          cursor: "pointer",
        }}
      >
        <CalendarMonthOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Sortie")}
          </Typography>
          <DatePickerWrapper>
            <DatePicker
              showYearDropdown
              locale={currentLanguage}
              minDate={minEndDate}
              maxDate={
                pmsType === "Skidata" && ticketType === "Réservation simple"
                  ? maxEndDate
                  : undefined
              }
              selected={endDate}
              onChange={(date: any) => setEndDate(date)}
              customInput={
                <CustomDateInput
                  label={t("Sélectionner une date")}
                  start={endDate}
                />
              }
            />
          </DatePickerWrapper>
        </div>
      </Box>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          p: 1,
          cursor: "pointer",
        }}
      >
        <ClockOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Heure")}
          </Typography>
          <TimePickerWrapper>
            <DatePicker
              locale={currentLanguage}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={5}
              dateFormat={currentLanguage === "en" ? "HH:mm" : "hh:mm"}
              timeFormat="HH:mm"
              selected={selectedTime}
              onChange={handleTimeChange}
              minTime={
                showFullTime
                  ? new Date(new Date().setHours(0, 0, 0, 0))
                  : minTime
              }
              maxTime={showEndOfDay ? endOfDayAsDate : maxTime} // sets maximum time to end of the day
              customInput={
                <CustomInputTime
                  label={t("Sélectionner une date")}
                  start={selectedTime}
                />
              }
            />
          </TimePickerWrapper>
        </div>
      </Box>
    </Box>
  );
};

export const KeyAccountEntryDatePickerUpdate: FC<any> = ({
  getStartDate,
  reservStartDate,
}) => {
  // ** translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  registerLocale(
    currentLanguage,
    currentLanguage === "fr" ? fr : currentLanguage === "nl" ? nl : en
  );

  // testing here -1 hour caused by the timezone / check the output in Belguim
  //const minusHour = moment(reservStartDate).subtract({ hours: 1 }).format();

  const starterData = new Date(reservStartDate);
  const starterTimeData = new Date(starterData.getTime());
  // it should be + 30 but the time zone keeps on adding 1h
  starterTimeData.setUTCMinutes(starterTimeData.getUTCMinutes() - 60);

  const [startDate, setStartDate] = useState<any>(starterTimeData);
  const [selectedTime, setSelectedTime] = useState<any>(starterTimeData);
  const handleTimeChange = (time: any) => {
    setSelectedTime(time);
  };

  useEffect(() => {
    getStartDate(startDate, selectedTime);
    // eslint-disable-next-line
  }, [startDate, selectedTime]);

  // ** Disable previous hours => start from current time until the end of the day (minTime/maxTime)
  const minTime = moment().toDate();
  if (moment(startDate).isSame(moment(), "day")) {
    minTime.setHours(moment().hours());
    minTime.setMinutes(moment().minutes());
  } else {
    minTime.setHours(0);
    minTime.setMinutes(0);
  }
  const endOfDay = moment(new Date()).endOf("day");
  const endOfDayAsDate = endOfDay.toDate();

  return (
    <Box
      sx={{
        flex: 1,
        border: "1px solid #D9D9D9",
        borderRadius: "0.4rem",
        display: "flex",
        color: "#8A8A8A",
        justifyContent: "space-between",
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          borderRight: "1px solid #D9D9D9",
          p: 1,
          cursor: "pointer",
        }}
      >
        <CalendarMonthOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Entrée")}
          </Typography>
          <DatePickerWrapper>
            <DatePicker
              showYearDropdown
              locale={currentLanguage}
              minDate={new Date()}
              selected={startDate}
              onChange={(date: any) => setStartDate(date)}
              customInput={
                <CustomDateInput
                  label={t("Sélectionner une date")}
                  start={startDate}
                />
              }
            />
          </DatePickerWrapper>
        </div>
      </Box>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          p: 1,
          cursor: "pointer",
        }}
      >
        <ClockOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Heure")}
          </Typography>
          <TimePickerWrapper>
            <DatePicker
              locale={currentLanguage}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={5}
              dateFormat={currentLanguage === "en" ? "HH:mm" : "hh:mm"}
              timeFormat="HH:mm"
              selected={selectedTime}
              onChange={handleTimeChange}
              minTime={minTime}
              maxTime={endOfDayAsDate} // sets maximum time to end of the day
              customInput={
                <CustomInputTime
                  label={t("Sélectionner une date")}
                  start={selectedTime}
                />
              }
            />
          </TimePickerWrapper>
        </div>
      </Box>
    </Box>
  );
};

export const KeyAccountExitDatePickerUpdate: FC<any> = ({
  getEndDate,
  reservEndDate,
  startDate,
  finalEndDate,
  pmsType,
  ticketType,
}) => {
  // ** translation
  // ** translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  registerLocale(
    currentLanguage,
    currentLanguage === "fr" ? fr : currentLanguage === "nl" ? nl : en
  );

  const starterEndData = new Date(reservEndDate);
  const starterTimeData = new Date(starterEndData.getTime());
  // it should be + 30 but the time zone keeps on adding 1h
  starterTimeData.setUTCMinutes(starterTimeData.getUTCMinutes() - 60);

  const [minEndDate, setMinEndDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(starterTimeData);
  const [maxEndDate, setMaxEndDate] = useState<any>(new Date());

  const [minTime, setMinTime] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState<any>(starterTimeData);
  const [maxTime, setMaxTime] = useState<any>(new Date());

  const [showFullTime, setShowFullTime] = useState(false);
  const [showEndOfDay, setShowEndOfDay] = useState(false);

  const handleTimeChange = (time: any) => {
    setSelectedTime(time);
  };

  useEffect(() => {
    getEndDate(endDate, selectedTime);
    // eslint-disable-next-line
  }, [endDate, selectedTime]);

  // ** Disable previous hours => start from current time until the end of the day (minTime/maxTime)
  useEffect(() => {
    const startDateTime = new Date(startDate);
    const timeData = new Date(startDateTime.getTime());
    // it should be + 30 but the time zone keeps on adding 1h
    timeData.setUTCMinutes(timeData.getUTCMinutes() - 60);

    setMinEndDate(timeData);
    setMinTime(timeData);

    const starterDate = moment.utc(startDate).format("YYYY-MM-DD");
    const enderDate = moment.utc(finalEndDate).format("YYYY-MM-DD");

    const isAfter = moment.utc(startDate).isAfter(moment.utc(finalEndDate));

    if (pmsType === "Skidata" && ticketType === "Réservation simple") {
      const getMaxdate = calculateMaxDateForSkidataSimpleRes(startDate, 89280);
      const maxDate = moment.utc(getMaxdate).format("YYYY-MM-DD");

      const limitDateTime = new Date(getMaxdate);
      const maxDateTime = new Date(limitDateTime.getTime());
      // it should be + 30 but the time zone keeps on adding 1h
      maxDateTime.setUTCMinutes(maxDateTime.getUTCMinutes() - 60);

      setMaxEndDate(maxDateTime);

      if (enderDate > maxDate) {
        setShowFullTime(true);
        setMaxTime(maxDateTime);
        setEndDate(maxDateTime);
        setSelectedTime(maxDateTime);
        setShowEndOfDay(false);
      } else if (maxDate > enderDate) {
        setShowFullTime(true);
        setShowEndOfDay(true);
      } else if (enderDate === maxDate) {
        setShowFullTime(true);
        setShowEndOfDay(false);
        setMaxTime(maxDateTime);
      }
    }

    if (isAfter) {
      setEndDate(timeData);
      setSelectedTime(timeData);
    }

    if (starterDate === enderDate) {
      setShowFullTime(false);
      setMinTime(timeData);
      //setSelectedTime(timeData);
      setShowEndOfDay(true);
    }

    if (enderDate > starterDate) {
      setShowFullTime(true);
    } else {
      setShowFullTime(false);
    }
  }, [endDate, startDate, finalEndDate, ticketType]);

  const endOfDay = moment(new Date()).endOf("day");
  const endOfDayAsDate = endOfDay.toDate();

  return (
    <Box
      sx={{
        flex: 1,
        border: "1px solid #D9D9D9",
        borderRadius: "0.4rem",
        display: "flex",
        color: "#8A8A8A",
        justifyContent: "space-between",
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          borderRight: "1px solid #D9D9D9",
          p: 1,
          cursor: "pointer",
        }}
      >
        <CalendarMonthOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Sortie")}
          </Typography>
          <DatePickerWrapper>
            <DatePicker
              showYearDropdown
              locale={currentLanguage}
              minDate={minEndDate}
              maxDate={
                pmsType === "Skidata" && ticketType === "Réservation simple"
                  ? maxEndDate
                  : undefined
              }
              selected={endDate}
              onChange={(date: any) => setEndDate(date)}
              customInput={
                <CustomDateInput
                  label={t("Sélectionner une date")}
                  start={endDate}
                />
              }
            />
          </DatePickerWrapper>
        </div>
      </Box>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          p: 1,
          cursor: "pointer",
        }}
      >
        <ClockOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Heure")}
          </Typography>
          <TimePickerWrapper>
            <DatePicker
              locale={currentLanguage}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={5}
              dateFormat={currentLanguage === "en" ? "HH:mm" : "hh:mm"}
              timeFormat="HH:mm"
              selected={selectedTime}
              onChange={handleTimeChange}
              minTime={
                showFullTime
                  ? new Date(new Date().setHours(0, 0, 0, 0))
                  : minTime
              }
              maxTime={showEndOfDay ? endOfDayAsDate : maxTime} // sets maximum time to end of the day
              customInput={
                <CustomInputTime
                  label={t("Sélectionner une date")}
                  start={selectedTime}
                />
              }
            />
          </TimePickerWrapper>
        </div>
      </Box>
    </Box>
  );
};

//calculate max end date
const calculateMaxDate = (inputDateStr: any, daysLimit: any) => {
  const inputDate = new Date(inputDateStr);
  const resultDate = new Date(
    inputDate.getTime() + parseInt(daysLimit) * 24 * 60 * 60 * 1000
  );
  return resultDate.toISOString();
};

//UCL date time picker
export const UclEntryDatePicker: FC<any> = ({ getStartDate }) => {
  // ** translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  registerLocale(
    currentLanguage,
    currentLanguage === "fr" ? fr : currentLanguage === "nl" ? nl : en
  );

  const [startDate, setStartDate] = useState<any>(new Date());
  const [selectedTime, setSelectedTime] = useState<any>(new Date());
  const handleTimeChange = (time: any) => {
    setSelectedTime(time);
  };

  useEffect(() => {
    const times = moment().toDate();
    const currentDateTime = moment();
    const startDateTime = moment(startDate);

    const sameDate = currentDateTime.isSame(startDateTime, "day");
    const isStartDateTimeBeforeCurrent =
      currentDateTime.format("HH:mm") > startDateTime.format("HH:mm");

    if (sameDate && isStartDateTimeBeforeCurrent) {
      times.setHours(moment().hours());
      times.setMinutes(moment().minutes());
      setSelectedTime(times);
    }
    // eslint-disable-next-line
  }, [startDate]);

  useEffect(() => {
    getStartDate(startDate, selectedTime);
    // eslint-disable-next-line
  }, [startDate, selectedTime]);

  // ** Disable previous hours => start from current time until the end of the day (minTime/maxTime)
  const minTime = moment().toDate();
  if (moment(startDate).isSame(moment(), "day")) {
    minTime.setHours(moment().hours());
    minTime.setMinutes(moment().minutes());
  } else {
    minTime.setHours(0);
    minTime.setMinutes(0);
  }
  const endOfDay = moment(new Date()).endOf("day");
  const endOfDayAsDate = endOfDay.toDate();
  return (
    <Box
      sx={{
        flex: 1,
        border: "1px solid #D9D9D9",
        borderRadius: "0.4rem",
        display: "flex",
        color: "#8A8A8A",
        justifyContent: "space-between",
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          borderRight: "1px solid #D9D9D9",
          p: 1,
          cursor: "pointer",
        }}
      >
        <CalendarMonthOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Entrée")}
          </Typography>
          <DatePickerWrapper>
            <DatePicker
              showYearDropdown
              locale={currentLanguage}
              minDate={new Date()}
              selected={startDate}
              onChange={(date: any) => setStartDate(date)}
              customInput={
                <CustomDateInput
                  label={t("Sélectionner une date")}
                  start={startDate}
                />
              }
            />
          </DatePickerWrapper>
        </div>
      </Box>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          p: 1,
          cursor: "pointer",
        }}
      >
        <ClockOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Heure")}
          </Typography>
          <TimePickerWrapper>
            <DatePicker
              locale={currentLanguage}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={5}
              dateFormat={currentLanguage === "en" ? "HH:mm" : "hh:mm"}
              timeFormat="HH:mm"
              selected={selectedTime}
              onChange={handleTimeChange}
              minTime={minTime}
              maxTime={endOfDayAsDate} // sets maximum time to end of the day
              customInput={
                <CustomInputTime
                  label={t("Sélectionner une date")}
                  start={selectedTime}
                />
              }
            />
          </TimePickerWrapper>
        </div>
      </Box>
    </Box>
  );
};

export const UclExitDatePicker: FC<any> = ({
  getEndDate,
  startDate,
  finalEndDate,
  daysLimit,
}) => {
  // ** translation
  // ** translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  registerLocale(
    currentLanguage,
    currentLanguage === "fr" ? fr : currentLanguage === "nl" ? nl : en
  );

  const [minEndDate, setMinEndDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [maxEndDate, setMaxEndDate] = useState<any>(new Date());

  const [selectedTime, setSelectedTime] = useState<any>(new Date());
  const [minTime, setMinTime] = useState<any>(new Date());
  const [maxTime, setMaxTime] = useState<any>(new Date());

  const [showFullTime, setShowFullTime] = useState(false);
  const [showEndOfDay, setShowEndOfDay] = useState(false);

  const handleTimeChange = (time: any) => {
    setSelectedTime(time);
  };

  useEffect(() => {
    getEndDate(endDate, selectedTime);
    // eslint-disable-next-line
  }, [endDate, selectedTime]);

  useEffect(() => {
    const startDateTime = new Date(startDate);
    const timeData = new Date(startDateTime.getTime());
    // it should be + 30 but the time zone keeps on adding 1h
    timeData.setUTCMinutes(timeData.getUTCMinutes() - 60);

    setMinEndDate(timeData);
    setMinTime(timeData);

    const starterDate = moment.utc(startDate).format("YYYY-MM-DD");
    const enderDate = moment.utc(finalEndDate).format("YYYY-MM-DD");

    const getMaxdate = calculateMaxDate(startDate, daysLimit);
    const maxDate = moment.utc(getMaxdate).format("YYYY-MM-DD");

    const limitDateTime = new Date(getMaxdate);
    const maxDateTime = new Date(limitDateTime.getTime());
    // it should be + 30 but the time zone keeps on adding 1h
    maxDateTime.setUTCMinutes(maxDateTime.getUTCMinutes() - 60);

    setMaxEndDate(maxDateTime);

    const isAfter = moment.utc(startDate).isAfter(moment.utc(finalEndDate));
    if (isAfter) {
      setEndDate(timeData);
      setSelectedTime(timeData);
    }

    if (starterDate === enderDate) {
      setShowFullTime(false);
      setMinTime(timeData);
      //setSelectedTime(timeData);
      setShowEndOfDay(true);
    } else if (enderDate > maxDate) {
      setShowFullTime(true);
      setMaxTime(maxDateTime);
      setEndDate(maxDateTime);
      setSelectedTime(maxDateTime);
      setShowEndOfDay(false);
    } else if (maxDate > enderDate) {
      setShowFullTime(true);
      setShowEndOfDay(true);
    } else if (enderDate === maxDate) {
      setShowFullTime(true);
      setShowEndOfDay(false);
      setMaxTime(maxDateTime);
    }
  }, [startDate, finalEndDate]);

  const endOfDay = moment(new Date()).endOf("day");
  const endOfDayAsDate = endOfDay.toDate();

  const handleDate = (date: any) => {
    setEndDate(date);
  };

  return (
    <Box
      sx={{
        flex: 1,
        border: "1px solid #D9D9D9",
        borderRadius: "0.4rem",
        display: "flex",
        color: "#8A8A8A",
        justifyContent: "space-between",
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          borderRight: "1px solid #D9D9D9",
          p: 1,
          cursor: "pointer",
        }}
      >
        <CalendarMonthOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Sortie")}
          </Typography>
          <DatePickerWrapper>
            <DatePicker
              showYearDropdown
              locale={currentLanguage}
              minDate={minEndDate}
              maxDate={maxEndDate}
              selected={endDate}
              onChange={handleDate}
              customInput={
                <CustomDateInput
                  label={t("Sélectionner une date")}
                  start={endDate}
                />
              }
            />
          </DatePickerWrapper>
        </div>
      </Box>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          p: 1,
          cursor: "pointer",
        }}
      >
        <ClockOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Heure")}
          </Typography>
          <TimePickerWrapper>
            <DatePicker
              locale={currentLanguage}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={5}
              dateFormat={currentLanguage === "en" ? "HH:mm" : "hh:mm"}
              timeFormat="HH:mm"
              selected={selectedTime}
              onChange={handleTimeChange}
              minTime={
                showFullTime
                  ? new Date(new Date().setHours(0, 0, 0, 0))
                  : minTime
              }
              maxTime={showEndOfDay ? endOfDayAsDate : maxTime} // sets maximum time to end of the day
              customInput={
                <CustomInputTime
                  label={t("Sélectionner une date")}
                  start={selectedTime}
                />
              }
            />
          </TimePickerWrapper>
        </div>
      </Box>
    </Box>
  );
};

export const UclEntryDatePickerUpdate: FC<any> = ({
  getStartDate,
  reservStartDate,
}) => {
  // ** translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  registerLocale(
    currentLanguage,
    currentLanguage === "fr" ? fr : currentLanguage === "nl" ? nl : en
  );

  // testing here -1 hour caused by the timezone / check the output in Belguim
  const starterData = new Date(reservStartDate);
  const starterTimeData = new Date(starterData.getTime());
  // it should be + 30 but the time zone keeps on adding 1h
  starterTimeData.setUTCMinutes(starterTimeData.getUTCMinutes() - 60);

  const [startDate, setStartDate] = useState<any>(starterTimeData);
  const [selectedTime, setSelectedTime] = useState<any>(starterTimeData);
  const handleTimeChange = (time: any) => {
    setSelectedTime(time);
  };

  useEffect(() => {
    const times = moment().toDate();
    const currentDateTime = moment();
    const startDateTime = moment(startDate);

    const sameDate = currentDateTime.isSame(startDateTime, "day");
    const isStartDateTimeBeforeCurrent =
      currentDateTime.format("HH:mm") > startDateTime.format("HH:mm");

    if (sameDate && isStartDateTimeBeforeCurrent) {
      times.setHours(moment().hours());
      times.setMinutes(moment().minutes());
      setSelectedTime(times);
    }
    // eslint-disable-next-line
  }, [startDate]);

  useEffect(() => {
    getStartDate(startDate, selectedTime);
    // eslint-disable-next-line
  }, [startDate, selectedTime]);

  // ** Disable previous hours => start from current time until the end of the day (minTime/maxTime)
  const minTime = moment().toDate();
  if (moment(startDate).isSame(moment(), "day")) {
    minTime.setHours(moment().hours());
    minTime.setMinutes(moment().minutes());
  } else {
    minTime.setHours(0);
    minTime.setMinutes(0);
  }
  const endOfDay = moment(new Date()).endOf("day");
  const endOfDayAsDate = endOfDay.toDate();
  return (
    <Box
      sx={{
        flex: 1,
        border: "1px solid #D9D9D9",
        borderRadius: "0.4rem",
        display: "flex",
        color: "#8A8A8A",
        justifyContent: "space-between",
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          borderRight: "1px solid #D9D9D9",
          p: 1,
          cursor: "pointer",
        }}
      >
        <CalendarMonthOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Entrée")}
          </Typography>
          <DatePickerWrapper>
            <DatePicker
              showYearDropdown
              locale={currentLanguage}
              minDate={new Date()}
              selected={startDate}
              onChange={(date: any) => setStartDate(date)}
              customInput={
                <CustomDateInput
                  label={t("Sélectionner une date")}
                  start={startDate}
                />
              }
            />
          </DatePickerWrapper>
        </div>
      </Box>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          p: 1,
          cursor: "pointer",
        }}
      >
        <ClockOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Heure")}
          </Typography>
          <TimePickerWrapper>
            <DatePicker
              locale={currentLanguage}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              dateFormat={currentLanguage === "en" ? "HH:mm" : "hh:mm"}
              timeFormat="HH:mm"
              selected={selectedTime}
              onChange={handleTimeChange}
              minTime={minTime}
              maxTime={endOfDayAsDate} // sets maximum time to end of the day
              customInput={
                <CustomInputTime
                  label={t("Sélectionner une date")}
                  start={selectedTime}
                />
              }
            />
          </TimePickerWrapper>
        </div>
      </Box>
    </Box>
  );
};

export const UclExitDatePickerUpdate: FC<any> = ({
  getEndDate,
  reservEndDate,
  startDate,
  finalEndDate,
  daysLimit,
}) => {
  // ** translation
  // ** translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  registerLocale(
    currentLanguage,
    currentLanguage === "fr" ? fr : currentLanguage === "nl" ? nl : en
  );

  const starterEndData = new Date(reservEndDate);
  const starterTimeData = new Date(starterEndData.getTime());
  // it should be + 30 but the time zone keeps on adding 1h
  starterTimeData.setUTCMinutes(starterTimeData.getUTCMinutes() - 60);

  const [minEndDate, setMinEndDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(starterTimeData);
  const [maxEndDate, setMaxEndDate] = useState<any>(new Date());

  const [selectedTime, setSelectedTime] = useState<any>(starterTimeData);
  const [minTime, setMinTime] = useState<any>(new Date());
  const [maxTime, setMaxTime] = useState<any>(new Date());

  const [showFullTime, setShowFullTime] = useState(false);
  const [showEndOfDay, setShowEndOfDay] = useState(false);

  const handleTimeChange = (time: any) => {
    setSelectedTime(time);
  };

  useEffect(() => {
    getEndDate(endDate, selectedTime);
    // eslint-disable-next-line
  }, [endDate, selectedTime]);

  // useEffect(() => {
  //   if (moment(endDate).isAfter(moment(maxEndDate), "day")) {
  //     setEndDate(new Date(maxEndDate));
  //   }
  // }, [endDate, maxEndDate]);

  // ** Disable previous hours => start from current time until the end of the day (minTime/maxTime)
  useEffect(() => {
    const startDateTime = new Date(startDate);
    const timeData = new Date(startDateTime.getTime());
    // it should be + 30 but the time zone keeps on adding 1h
    timeData.setUTCMinutes(timeData.getUTCMinutes() - 60);

    setMinEndDate(timeData);
    setMinTime(timeData);

    const starterDate = moment.utc(startDate).format("YYYY-MM-DD");
    const enderDate = moment.utc(finalEndDate).format("YYYY-MM-DD");

    const getMaxdate = calculateMaxDate(startDate, daysLimit);
    const maxDate = moment.utc(getMaxdate).format("YYYY-MM-DD");

    const limitDateTime = new Date(getMaxdate);
    const maxDateTime = new Date(limitDateTime.getTime());
    // it should be + 30 but the time zone keeps on adding 1h
    maxDateTime.setUTCMinutes(maxDateTime.getUTCMinutes() - 60);

    setMaxEndDate(maxDateTime);

    const isAfter = moment.utc(startDate).isAfter(moment.utc(finalEndDate));
    if (isAfter) {
      setEndDate(timeData);
      setSelectedTime(timeData);
    }

    if (starterDate === enderDate) {
      setShowFullTime(false);
      setMinTime(timeData);
      //setSelectedTime(timeData);
      setShowEndOfDay(true);
    } else if (enderDate > maxDate) {
      setShowFullTime(true);
      setMaxTime(maxDateTime);
      setEndDate(maxDateTime);
      setSelectedTime(maxDateTime);
      setShowEndOfDay(false);
    } else if (maxDate > enderDate) {
      setShowFullTime(true);
      setShowEndOfDay(true);
    } else if (enderDate === maxDate) {
      setShowFullTime(true);
      setShowEndOfDay(false);
      setMaxTime(maxDateTime);
    }
  }, [startDate, finalEndDate]);

  const endOfDay = moment(new Date()).endOf("day");
  const endOfDayAsDate = endOfDay.toDate();

  const handleDate = (date: any) => {
    setEndDate(date);
  };

  return (
    <Box
      sx={{
        flex: 1,
        border: "1px solid #D9D9D9",
        borderRadius: "0.4rem",
        display: "flex",
        color: "#8A8A8A",
        justifyContent: "space-between",
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          borderRight: "1px solid #D9D9D9",
          p: 1,
          cursor: "pointer",
        }}
      >
        <CalendarMonthOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Sortie")}
          </Typography>
          <DatePickerWrapper>
            <DatePicker
              showYearDropdown
              locale={currentLanguage}
              minDate={minEndDate}
              maxDate={maxEndDate}
              selected={endDate}
              onChange={handleDate}
              customInput={
                <CustomDateInput
                  label={t("Sélectionner une date")}
                  start={endDate}
                />
              }
            />
          </DatePickerWrapper>
        </div>
      </Box>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          p: 1,
          cursor: "pointer",
        }}
      >
        <ClockOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Heure")}
          </Typography>
          <TimePickerWrapper>
            <DatePicker
              locale={currentLanguage}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={5}
              dateFormat={currentLanguage === "en" ? "HH:mm" : "hh:mm"}
              timeFormat="HH:mm"
              selected={selectedTime}
              onChange={handleTimeChange}
              minTime={
                showFullTime
                  ? new Date(new Date().setHours(0, 0, 0, 0))
                  : minTime
              }
              maxTime={showEndOfDay ? endOfDayAsDate : maxTime} // sets maximum time to end of the day
              customInput={
                <CustomInputTime
                  label={t("Sélectionner une date")}
                  start={selectedTime}
                />
              }
            />
          </TimePickerWrapper>
        </div>
      </Box>
    </Box>
  );
};

// key Account Subscription
export const KeyAccountSubscriptionEntryDatePicker: FC<any> = ({
  getStartDate,
}) => {
  // ** translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  registerLocale(
    currentLanguage,
    currentLanguage === "fr" ? fr : currentLanguage === "nl" ? nl : en
  );

  const [startDate, setStartDate] = useState<any>(new Date());

  useEffect(() => {
    getStartDate(startDate, new Date().setHours(0, 0, 0, 0));
    // eslint-disable-next-line
  }, [startDate]);

  return (
    <Box
      sx={{
        flex: 1,
        border: "1px solid #D9D9D9",
        borderRadius: "0.4rem",
        display: "flex",
        color: "#8A8A8A",
        justifyContent: "space-between",
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          borderRight: "1px solid #D9D9D9",
          p: 1,
          cursor: "pointer",
        }}
      >
        <CalendarMonthOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Entrée")}
          </Typography>
          <DatePickerWrapper>
            <DatePicker
              showYearDropdown
              locale={currentLanguage}
              minDate={new Date()}
              selected={startDate}
              onChange={(date: any) => setStartDate(date)}
              customInput={
                <CustomDateInput
                  label={t("Sélectionner une date")}
                  start={startDate}
                />
              }
            />
          </DatePickerWrapper>
        </div>
      </Box>
    </Box>
  );
};

export const KeyAccountSubscriptionExitDatePicker: FC<any> = ({
  getEndDate,
  startDate,
  finalEndDate,
}) => {
  // ** translation
  // ** translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  registerLocale(
    currentLanguage,
    currentLanguage === "fr" ? fr : currentLanguage === "nl" ? nl : en
  );

  const [minEndDate, setMinEndDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [maxEndDate, setMaxEndDate] = useState<any>(new Date());

  useEffect(() => {
    getEndDate(endDate, new Date().setHours(0, 0, 0, 0));
    // eslint-disable-next-line
  }, [endDate]);

  // ** Disable previous hours => start from current time until the end of the day (minTime/maxTime)
  useEffect(() => {
    const startDateTime = new Date(startDate);
    const timeData = new Date(startDateTime.getTime());
    // it should be + 30 but the time zone keeps on adding 1h
    timeData.setUTCMinutes(timeData.getUTCMinutes() - 60);

    setMinEndDate(timeData);

    const enderDate = moment.utc(finalEndDate).format("YYYY-MM-DD");

    const getMindate = moment.utc(startDate).add(1, "month").toDate();
    const getMaxdate = moment.utc(startDate).add(10, "year").toDate();
    const maxDate = moment.utc(getMaxdate).format("YYYY-MM-DD");

    const minLimit = new Date(getMindate);
    const minDateTime = new Date(minLimit.getTime());
    // it should be + 30 but the time zone keeps on adding 1h
    minDateTime.setUTCMinutes(minDateTime.getUTCMinutes() - 60);

    setMinEndDate(minDateTime);

    const limitDateTime = new Date(getMaxdate);
    const maxDateTime = new Date(limitDateTime.getTime());
    // it should be + 30 but the time zone keeps on adding 1h
    maxDateTime.setUTCMinutes(maxDateTime.getUTCMinutes() - 60);

    setMaxEndDate(maxDateTime);

    if (enderDate > maxDate) {
      setEndDate(maxDateTime);
    }

    const isAfter = moment.utc(finalEndDate).isBefore(moment.utc(minDateTime));
    if (isAfter) {
      setEndDate(minDateTime);
    }
  }, [startDate, finalEndDate]);

  return (
    <Box
      sx={{
        flex: 1,
        border: "1px solid #D9D9D9",
        borderRadius: "0.4rem",
        display: "flex",
        color: "#8A8A8A",
        justifyContent: "space-between",
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          borderRight: "1px solid #D9D9D9",
          p: 1,
          cursor: "pointer",
        }}
      >
        <CalendarMonthOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Sortie")}
          </Typography>
          <DatePickerWrapper>
            <DatePicker
              showYearDropdown
              locale={currentLanguage}
              minDate={minEndDate}
              maxDate={maxEndDate}
              selected={endDate}
              onChange={(date: any) => setEndDate(date)}
              customInput={
                <CustomDateInput
                  label={t("Sélectionner une date")}
                  start={endDate}
                />
              }
            />
          </DatePickerWrapper>
        </div>
      </Box>
    </Box>
  );
};

export const KeyAccountSubscriptionEntryDatePickerUpdate: FC<any> = ({
  getStartDate,
  reservStartDate,
}) => {
  // ** translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  registerLocale(
    currentLanguage,
    currentLanguage === "fr" ? fr : currentLanguage === "nl" ? nl : en
  );

  // testing here -1 hour caused by the timezone / check the output in Belguim
  //const minusHour = moment(reservStartDate).subtract({ hours: 1 }).format();

  const starterData = new Date(reservStartDate);
  const starterTimeData = new Date(starterData.getTime());
  // it should be + 30 but the time zone keeps on adding 1h
  starterTimeData.setUTCMinutes(starterTimeData.getUTCMinutes() - 60);

  const [startDate, setStartDate] = useState<any>(starterTimeData);

  useEffect(() => {
    getStartDate(startDate, new Date().setHours(0, 0, 0, 0));
    // eslint-disable-next-line
  }, [startDate]);

  return (
    <Box
      sx={{
        flex: 1,
        border: "1px solid #D9D9D9",
        borderRadius: "0.4rem",
        display: "flex",
        color: "#8A8A8A",
        justifyContent: "space-between",
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          borderRight: "1px solid #D9D9D9",
          p: 1,
          cursor: "pointer",
        }}
      >
        <CalendarMonthOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Entrée")}
          </Typography>
          <DatePickerWrapper>
            <DatePicker
              disabled
              showYearDropdown
              locale={currentLanguage}
              minDate={new Date()}
              selected={startDate}
              onChange={(date: any) => setStartDate(date)}
              customInput={
                <CustomDateInput
                  label={t("Sélectionner une date")}
                  start={startDate}
                />
              }
            />
          </DatePickerWrapper>
        </div>
      </Box>
    </Box>
  );
};

export const KeyAccountSubscriptionExitDatePickerUpdate: FC<any> = ({
  getEndDate,
  reservEndDate,
  startDate,
  finalEndDate,
}) => {
  // ** translation
  // ** translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  registerLocale(
    currentLanguage,
    currentLanguage === "fr" ? fr : currentLanguage === "nl" ? nl : en
  );

  const starterEndData = new Date(reservEndDate);
  const starterTimeData = new Date(starterEndData.getTime());
  // it should be + 30 but the time zone keeps on adding 1h
  starterTimeData.setUTCMinutes(starterTimeData.getUTCMinutes() - 60);

  const [minEndDate, setMinEndDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(starterTimeData);
  const [maxEndDate, setMaxEndDate] = useState<any>(new Date());

  useEffect(() => {
    getEndDate(endDate, new Date().setHours(0, 0, 0, 0));
    // eslint-disable-next-line
  }, [endDate]);

  // ** Disable previous hours => start from current time until the end of the day (minTime/maxTime)
  useEffect(() => {
    const startDateTime = new Date(startDate);
    const timeData = new Date(startDateTime.getTime());
    // it should be + 30 but the time zone keeps on adding 1h
    timeData.setUTCMinutes(timeData.getUTCMinutes() - 60);

    setMinEndDate(timeData);

    const enderDate = moment.utc(finalEndDate).format("YYYY-MM-DD");

    const getMindate = moment.utc(startDate).add(1, "month").toDate();
    const getMaxdate = moment.utc(startDate).add(10, "year").toDate();
    const maxDate = moment.utc(getMaxdate).format("YYYY-MM-DD");

    const minLimit = new Date(getMindate);
    const minDateTime = new Date(minLimit.getTime());
    // it should be + 30 but the time zone keeps on adding 1h
    minDateTime.setUTCMinutes(minDateTime.getUTCMinutes() - 60);

    setMinEndDate(minDateTime);

    const limitDateTime = new Date(getMaxdate);
    const maxDateTime = new Date(limitDateTime.getTime());
    // it should be + 30 but the time zone keeps on adding 1h
    maxDateTime.setUTCMinutes(maxDateTime.getUTCMinutes() - 60);

    setMaxEndDate(maxDateTime);

    if (enderDate > maxDate) {
      setEndDate(maxDateTime);
    }

    const isAfter = moment.utc(finalEndDate).isBefore(moment.utc(minDateTime));
    if (isAfter) {
      setEndDate(minDateTime);
    }
  }, [endDate, startDate, finalEndDate]);

  return (
    <Box
      sx={{
        flex: 1,
        border: "1px solid #D9D9D9",
        borderRadius: "0.4rem",
        display: "flex",
        color: "#8A8A8A",
        justifyContent: "space-between",
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          borderRight: "1px solid #D9D9D9",
          p: 1,
          cursor: "pointer",
        }}
      >
        <CalendarMonthOutline sx={{ mr: 1, color: "rgb(0 0 0 / 49%)" }} />
        <div>
          <Typography sx={{ fontSize: "13px", p: 0, textAlign: "left" }}>
            {t("Sortie")}
          </Typography>
          <DatePickerWrapper>
            <DatePicker
              showYearDropdown
              locale={currentLanguage}
              minDate={minEndDate}
              maxDate={maxEndDate}
              selected={endDate}
              onChange={(date: any) => setEndDate(date)}
              customInput={
                <CustomDateInput
                  label={t("Sélectionner une date")}
                  start={endDate}
                />
              }
            />
          </DatePickerWrapper>
        </div>
      </Box>
    </Box>
  );
};
